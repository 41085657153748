import React, { useState, useReducer, useCallback, useContext } from "react";
import {
  dataFetchReducer
} from "../../../Utils/requestUtils";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import VisibilityIcon from "@material-ui/icons/Visibility";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import ErrorBoundary from "../../../Utils/ErrorBoundary";
import { useTranslation } from "react-i18next";
import AuthContext from "../../../../context/auth-context";

function KeyActionsDialog(props) {
  let { user_data } = useContext(AuthContext);
  const { t } = useTranslation();
  let {open, openKeyActionDialog, type} = props

  const key = openKeyActionDialog.key
  const title = openKeyActionDialog.action
  const key_type = type

  const [pageState, dispatchPageState] = useReducer(dataFetchReducer, {
    fetchedData: null,
    isLoading: true,
    isError: false,
  });

  const [isKeyVisible, setIsKeyVisible] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const generateKeyRequest = useCallback(
    (payload) => {
      dispatchPageState({type: "data_fetch_init"})
      let headers = {};
      if (user_data.business_group_key) {
        headers = {
          "Company-Key": props.company_key
        }
      }
      axios.post(`/dash/${key_type}/${props.service}`, payload,{
        headers: headers
      }).then(response=>{
        dispatchPageState({
          type: "data_fetch_success",
          payload: response.data
        })
        setAlertMessage(t("generate_key_success"));
        setAlertOpen(true);
        setTimeout(() => {
          setAlertOpen(false);
        }, 1000);
      }).catch(error => {
        if ((error.response || {}).status === 403) dispatchPageState({type: "data_fetch_failure_403"})
        else if ((error.response || {}).status === 404) dispatchPageState({type: "data_fetch_failure_404"})
        else dispatchPageState({type: "data_fetch_failure"})

        setAlertMessage(t("deactivated_key_error"));
        setAlertOpen(true);
        setTimeout(() => setAlertOpen(false), 3000);
      })
      // eslint-disable-next-line
    },[]
  )

  const genarateKey = () => {
    const payload = {}
    const timer_ = setTimeout(() => {
      generateKeyRequest(payload)
    }, 300);
    return () => {
      clearTimeout(timer_)
    }
  }

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setAlertMessage(t("copy_key_to_clipboard"));
      setAlertOpen(true);
      setTimeout(() => setAlertOpen(false), 3000);
    });
  }

  const contentContainer = (data) => {
    let api_key = ""
    if (key_type === "api_key"){
      api_key = data.api_key
      if (!api_key) {
        api_key = data.web_token
      }
    } else {
      api_key = data.mobile_token
    }
    return (
      <div className='emptyContainer' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 10 }}>
        <p className={["blueText", "subtitleSize"].join(" ")} style={{marginLeft: 30}}>
          {isKeyVisible ? api_key : '************************************'}
        </p>
        <div>
          <IconButton size="small" onClick={() => setIsKeyVisible(!isKeyVisible)} style={{margin: 10}}>
            <VisibilityIcon fontSize="small" />
          </IconButton>
          <IconButton size="small" onClick={() => copyToClipboard(api_key)} style={{margin: 10}}>
            <FileCopyIcon fontSize="small" />
          </IconButton>
        </div>
      </div>
    )
  }

  const exitDialog = () => {
    dispatchPageState({type: "data_fetch_init"});
    setIsKeyVisible(false);
    setAlertOpen(false);
    setInputValue('');
    setIsButtonDisabled(true);
    props.onClose();
  }

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    setIsButtonDisabled(value !== key);
  };

  const deactivateKeyRequest = useCallback(
    (payload, api_key) => {
      dispatchPageState({type: "data_fetch_init"})
      let headers = {};
      if (user_data.business_group_key) {
        headers = {
          "COMPANY-KEY": props.company_key
        }
      }
      axios.delete(`/dash/${key_type}/${props.service}/${api_key}`,{
        params: payload,
        headers: headers
      }).then(response=>{
        dispatchPageState({
          type: "data_fetch_success",
          payload: []
        })
        setAlertMessage(t("deactivated_key_success"));
        setAlertOpen(true);
        setTimeout(() => {
          setAlertOpen(false);
          exitDialog();
        }, 1000);
      }).catch(error => {
        if ((error.response || {}).status === 403) dispatchPageState({type: "data_fetch_failure_403"})
        else if ((error.response || {}).status === 404) dispatchPageState({type: "data_fetch_failure_404"})
        else dispatchPageState({type: "data_fetch_failure"})

        setAlertMessage(t("deactivated_key_error"));
        setAlertOpen(true);
        setTimeout(() => setAlertOpen(false), 3000);
      })
      // eslint-disable-next-line
    },[]
  )

  const deactivateKey = (api_key) => {
    const payload = {}
    const timer_ = setTimeout(() => {
      deactivateKeyRequest(payload, api_key)
    }, 300);
    return () => {
      clearTimeout(timer_)
    }
  }

  return (
    <div>
      <Dialog
      open={open}
      onClose={exitDialog}
      keepMounted={false}
      >
      <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", marginRight:"24px"}}>
        <DialogTitle style={{paddingBottom: "0px"}} className={["blueText", "normalMediumSize"].join(" ")}>{t(title)}</DialogTitle>
        <IconButton size="small" onClick={exitDialog}>
          <CloseIcon fontSize="small"/> {t("exit")}
        </IconButton >
      </div>
      <DialogContent >
        {title === "create_new_key" && (
          <div style={{height: "100px", marginTop: "10%"}}>
            <ErrorBoundary doNothing><div className={["normalText", "bold"].join(" ")}>{t("copy_key_text")}</div></ErrorBoundary>
            {!pageState.fetchedData && (
              <div style={{display: "flex", flexDirection:"column"}}>
                <div className="internalCardContainer" style={{display:"flex", justifyContent:"center"}}>
                  <div
                    style={{ padding: "5px 30px", margin:"5px", width:"150px", backgroundColor: "#0b1f82" }}
                    className={["button", "standard", "normalText", "normalMediumSize"].join(" ")}
                    onClick={genarateKey}
                  >
                    {t("generate_key")}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        {(pageState.fetchedData && (pageState.fetchedData.api_key || pageState.fetchedData.mobile_token || pageState.fetchedData.web_token)) && (
          <div>{contentContainer(pageState.fetchedData)}</div>
        )}
        {title === "deactivate_key" && (
          <div style={{height: "220px", marginTop: "30px", textAlign:"center"}}>
            <ErrorBoundary doNothing><div className={["normalText", "bold"].join(" ")}>{t("deactivate_key_text")}</div></ErrorBoundary>
            <div
              className={["listCardItem", "bold", "normalSmallSize"].join(" ")}
              style={{flex:"1 1 100px", textAlign:"center", marginTop: 20}}
            >
              {t("type")} <em style={{ margin: '0 10px' }}> {key} </em> {t("to_continue")}
            </div>
            <input
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              placeholder={t("type_key_placeholder")}
              style={{
                width: "100%",
                padding: "10px",
                margin: "20px 0 20px 0",
                borderRadius: "4px",
                border: "1px solid #ccc",
              }}
            />
            <div style={{display: "flex", flexDirection:"row", justifyContent: "space-around"}}>
                <div className="internalCardContainer" style={{display:"flex", justifyContent:"center"}}>
                  <div
                    style={{ padding: "5px 30px", margin:"5px", width:"150px", backgroundColor: "#aaa799" }}
                    className={["button", "standard", "normalText", "normalMediumSize"].join(" ")}
                    onClick={exitDialog}
                  >
                    {t("cancel")}
                  </div>
                </div>
                <button
                  style={{
                    backgroundColor: isButtonDisabled ? '#fff' : '#0b1f82',
                    color: isButtonDisabled ? '#0b1f82' : '#fff',
                    border: isButtonDisabled ? '1px solid #0b1f82' : 'none',
                    cursor: isButtonDisabled ? "not-allowed" : "pointer",
                    padding: '5px 30px',
                    margin:"20px 0",
                    width:"150px"
                  }}
                  className={["button", "normalText", "normalMediumSize"].join(" ")}
                  onClick={() =>{
                    deactivateKey(key)
                  }}
                  disabled={isButtonDisabled}
                >
                  {t("deactivate")}
                </button>
              </div>
          </div>
        )}
        </DialogContent>
      </Dialog>
      <Snackbar
        open={alertOpen}
        message={alertMessage}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        style={{ bottom: '80px' }}
      />
    </div>
  )
}

export default KeyActionsDialog;