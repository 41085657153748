import React, { useReducer, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import { useHistory } from "react-router-dom";
import axios from "axios"
import { CircularProgress } from '@material-ui/core';
import ConfirmationContent from "./ConfirmationContent"
import {decisionReducer} from "../../../../Utils/requestUtils"
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const risk_level_dictionary = {
    low: "Baixo Risco",
    medium: "Médio Risco",
    high: "Alto Risco"
}

function ConfirmationDialog(props) {
    const history = useHistory()
    let title = ""
    let buttonClass = ["button", "reproved", "normalText", "normalMediumSize"].join(" ");

    switch(props.manualAnalysisStatus) {
        case "manually_approved":
            title = "Aprovar";
            buttonClass = ["button", "approved", "normalText", "normalMediumSize"].join(" ")
            break;
        case "manually_reproved":
            title = "Reprovar";
            buttonClass = ["button", "reproved", "normalText", "normalMediumSize"].join(" ")
            break;
        case "manually_challenged":
            title = "Contestar";
            buttonClass = ["button", "challenged", "normalText", "normalMediumSize"].join(" ")
            break;
        case "on_hold":
            title = "COLOCAR EM ESPERA";
            buttonClass = ["button", "challenged", "normalText", "normalMediumSize"].join(" ")
            break;
        default:
            title = ""
    }

    const [dialogState, dispatchDialogState] = useReducer(
        decisionReducer,
        { isLoading: false, isError: false, finished: false }
    )

    const [manualAnalysisReason, setManualAnalysisReason] = useState(null);
    const [manualAnalysisFetchedStatus, setmanualAnalysisFetchedStatus] = useState(null);
    const [manualAnalysisReasons, setManualAnalysisReasons] = useState(null);

    if (props.manualAnalysisStatus && manualAnalysisFetchedStatus !== props.manualAnalysisStatus) {
        setManualAnalysisReasons(null);
        setManualAnalysisReason(null);
        setmanualAnalysisFetchedStatus(props.manualAnalysisStatus)
        axios.get('/dash/onboarding/manual_analysis_reasons?analysis_status_enum=' + props.manualAnalysisStatus).then( response => {
            setManualAnalysisReasons(response.data)
            if (response.data.length > 0){
                setManualAnalysisReason(response.data[0].enum)
            }else {
                setManualAnalysisReason(null)
            }
        }).catch(error => {
            if ((error.response || {}).status === 403) dispatchDialogState({type: "data_fetch_failure_403"})
            else if ((error.response || {}).status === 404) dispatchDialogState({type: "data_fetch_failure_404"})  
            else dispatchDialogState({type: "data_fetch_failure"})
        })	
    }

    const handleChangeManualAnalysisReason = (event) => {
        setManualAnalysisReason(event.target.value);
    }

    const handleFinish = () => {
        dispatchDialogState({ type: "send_request_init" })
        let payload={
            analysis_status: props.manualAnalysisStatus
        }
        if (props.data && props.data !== "") {
            payload = { ...payload, observation: props.data }
        }
        if (manualAnalysisReason){
            payload = {...payload, manual_analysis_reason_enum: manualAnalysisReason}
        }
        if (!!props.riskLevelStatus){
            payload = {...payload, risk_level: props.riskLevelStatus}
        }
        setTimeout(function () {
            axios.put('/dash/onboarding/legal_person/' + props.legal_person_key, payload).then(response => {
                dispatchDialogState({ type: "send_request_success" })
            }).catch(error => {
                if ((error.response || {}).status === 403) dispatchDialogState({ type: "send_request_failure_403" })
                else if ((error.response || {}).status === 409) dispatchDialogState({ type: "send_request_failure_409" })
                else dispatchDialogState({ type: "send_request_failure" })
            })
        }, 1000)

    }

    if (dialogState.finished && dialogState.isLoading) {
        return (
            <Dialog
                open={props.open}
                onClose={() => history.push("/legal-persons-inbox")}
                keepMounted={false}
                disableBackdropClick
                disableEscapeKeyDown
            >
                <DialogTitle style={{ paddingBottom: "0px" }} className={["blueText", "normalMediumSize"].join(" ")}>{title}</DialogTitle>
                <DialogContent>
                    <div style={{ display: "flex", width: "310px", height: "217px" }}>
                        <CircularProgress style={{ margin: "auto" }} />
                    </div>
                </DialogContent>
            </Dialog>

        )
    }
    else if (dialogState.finished) {
        let confirmationData = {
            message: dialogState.message,
            success: !dialogState.isError
        }
        return (
            <Dialog
                open={props.open}
                onClose={() => history.push("/legal-persons-inbox")}
                keepMounted={false}
                disableBackdropClick
            >
                <DialogTitle style={{ paddingBottom: "0px" }} className={["blueText", "normalMediumSize"].join(" ")}>Confirmação de Revisão</DialogTitle>
                <DialogContent>
                    <ConfirmationContent onClose={() => history.push("/legal-persons-inbox")} data={confirmationData} />
                </DialogContent>
            </Dialog>
        )
    }

    else if(!manualAnalysisReasons && !props.user_data.business_group_key){
        return (<Dialog 
                open={props.open} 
                onClose={() => history.push("/legal-persons-inbox")} 
                keepMounted={false}
                disableBackdropClick
                disableEscapeKeyDown
            >
                <DialogTitle style={{paddingBottom: "0px", width: "500px"}} className={["blueText", "normalMediumSize"].join(" ")}>{title}</DialogTitle>
                <DialogContent>
                    <div style={{display:"flex", width: "310px", height:"217px", margin:"auto"}}>
                        <CircularProgress style={{margin:"auto"}} />
                    </div>
                </DialogContent>
            </Dialog>);
    }

    return (
        <Dialog 
        open={props.open} 
        onClose={props.onClose} 
        keepMounted={false}
        >
            <DialogTitle style={{paddingBottom: "0px", width: "500px"}} className={["blueText", "normalMediumSize"].join(" ")}>{title}</DialogTitle>
            <DialogContent>
                { (manualAnalysisReasons || []).length > 0?
                <React.Fragment>
                    <div>Motivo</div>
                    <Select
                        value={manualAnalysisReason}
                        onChange={handleChangeManualAnalysisReason}
                        style={{width: "100%"}}
                    >
                    {manualAnalysisReasons.map((reason, index) => (<MenuItem value={reason.enum}>{reason.description}</MenuItem>))}
                    </Select>
                </React.Fragment> : null}
                <div>
                    <div>
                        <TextField
                            fullWidth
                            multiline
                            variant="outlined"
                            margin="dense"
                            label="Observação"
                            defaultValue={props.data ? props.data : "Sem Observação"}
                            disabled={true}
                        />
                    </div>
                        <TextField 
                            style={{margin: "20px 0px"}}        
                            fullWidth
                            multiline
                            variant="outlined"
                            label="Nível de Risco"
                            value={props.riskLevelStatus ? risk_level_dictionary[props.riskLevelStatus] : "Nível de Risco não definido"}
                            disabled={true}
                        />
                    <div className="internalCardContainer" style={{display:"flex", justifyContent:"center"}}>
                        <div 
                            className={buttonClass}
                            onClick={handleFinish}
                        >
                            {title}
                        </div>
                        <div 
                            className={["button", "cancel", "normalText", "normalMediumSize"].join(" ")}
                            onClick={props.onClose}
                        >
                            Cancelar 
                        </div>
                  </div>
              </div>
          </DialogContent>
        </Dialog>
    )
}

export default ConfirmationDialog