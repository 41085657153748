import React, { useState, useEffect, useReducer, useCallback, useContext } from "react";
import { dataFetchReducer } from "../../Utils/requestUtils";
import { CircularProgress, InputAdornment } from "@material-ui/core";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import SearchIcon from "@material-ui/icons/Search";
import axios from "axios";
import ServiceCard from "./ServiceCard/ServiceCard";
import ErrorBoundary from "../../Utils/ErrorBoundary";
import AuthContext from "../../../context/auth-context";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DESCRIPTIONS from "../utils/utilsDescriptions";

function ManageIntegrationInbox(props) {
  const { t } = useTranslation()
  let history = useHistory();
  let { user_data } = useContext(AuthContext);
  let roles = useContext(AuthContext).user_data.roles;

  if (!roles.includes("manage_integration")) {
    history.push("");
  }

  const [companies, setCompanies] = useState([]);
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');

  const [pageState, dispatchPageState] = useReducer(dataFetchReducer, {
    fetchedData: null,
    isLoading: true,
    isError: false,
  });

  const doRequest = useCallback(
    (payload) => {
      dispatchPageState({ type: "data_fetch_init" });
      axios.get('/dash/company', {
        params: payload
      }).then(response => {
        dispatchPageState({
          type: "data_fetch_success",
          payload: response.data.services
        });
      }).catch(error => {
        if ((error.response || {}).status === 403) dispatchPageState({ type: "data_fetch_failure_403" });
        else if ((error.response || {}).status === 404) dispatchPageState({ type: "data_fetch_failure_404" });
        else dispatchPageState({ type: "data_fetch_failure" });
      });
    }, []
  );

  const fetchBusinessGroupCompanies = useCallback(() => {
    if (user_data.business_group_key) {
      axios.get('/dash/business_group/companies', {
        params: {}
      }).then(response => {
        const businessGroupCompanies = response.data.companies.map(({ name, company_key }) => ({
          label: name,
          company_key
        }));
        setCompanies(businessGroupCompanies);
        setValue(businessGroupCompanies[0]);
      }).catch(error => {
        if ((error.response || {}).status === 403) dispatchPageState({ type: "data_fetch_failure_403" });
        else if ((error.response || {}).status === 404) dispatchPageState({ type: "data_fetch_failure_404" });
        else dispatchPageState({ type: "data_fetch_failure" });
      });
    }
  }, [user_data.business_group_key]);

  useEffect(() => {
    const timer_ = setTimeout(() => {
      if (user_data.business_group_key) {
        fetchBusinessGroupCompanies();
      } else {
        doRequest({});
      }
    }, 300);
    return () => {
      clearTimeout(timer_)
    }
  }, [user_data.business_group_key, fetchBusinessGroupCompanies, doRequest]);

  useEffect(() => {
    const timer_ = setTimeout(() => {
      if (value) {
        doRequest({ company_key: value.company_key });
      }
    }, 300);
    return () => {
      clearTimeout(timer_)
    }
  }, [value, doRequest]);

  const contentContainer = (dataList) => {
    let nr_of_cards = dataList.length;
    const filteredServices = dataList.filter(service => DESCRIPTIONS.hasOwnProperty(service.name));
    let cards = filteredServices.map((service) => (
      <ErrorBoundary key={service.name}>
        <ServiceCard service={service.name} company_key={value ? value.company_key : user_data.company_key} />
      </ErrorBoundary>
    ));

    if (nr_of_cards === 0) {
      return (
        <div className='emptyContainer'>
          <p className={["blueText", "subtitleSize"].join(" ")}>
            {t("no_products_registered")}
          </p>
        </div>
      );
    } else {
      return (
        <div className='cardContainer'>
          {cards}
        </div>
      );
    }
  };

  if (pageState.fetchedData) {
    return (
      <div style={{ width: "auto", paddingBottom: "30px" }}>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <div>
            <p className={["blueText", "titleSize"].join(" ")}>{t('manage_integration')}</p>
          </div>
          {user_data.business_group_key && (
            <div style={{ position: 'relative', marginRight: "0 30px", width: '300px' }}>
              <Autocomplete
                disablePortal
                options={companies}
                value={value}
                onChange={(event, newValue) => {
                  setValue(newValue);
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("select_the_company") + "..."}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </div>
          )}
        </div>
        <div>
          <p className={["normalText"].join(" ")}>
            {t("integration_description")}
          </p>
        </div>
        <div>{contentContainer(pageState.fetchedData)}</div>
      </div>
    );
  } else if (pageState.isError) {
    return (
      <div style={{ width: "auto" }}>
        <div style={{ display: "flex" }}>
          <div>
            <p className={["blueText", "titleSize"].join(" ")}>{t('manage_integration')}</p>
          </div>
        </div>
        <div style={{ height: "55vh", display: "flex" }}>
          <p className={["blueText", "titleSize"].join(" ")} style={{ margin: "auto", textAlign: "center" }}>
            {pageState.errorMessage}
          </p>
        </div>
      </div>
    );
  } else if (pageState.isLoading) {
    return (
      <div style={{ width: "auto" }}>
        <p className={["blueText", "titleSize"].join(" ")}>{t('manage_integration')}</p>
        <div className="circularProgressCenter">
          <CircularProgress />
        </div>
      </div>
    );
  } else return null;
}

export default ManageIntegrationInbox;
