import React, { useState, useEffect } from "react";
import DataField from "../../utils/DataField"
import {titleCase, clientAge, checkNested,formatPhone, formatAddress, getProperMetadata, retrieveReceitaFederalStatus, formatAsMoney} from "../../utils/utils"
import Tooltip from "@material-ui/core/Tooltip";
import { useTranslation } from "react-i18next";
import { TableBody, TableCell, TableRow } from "@material-ui/core";
import { Table } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import Paper from '@material-ui/core/Paper';
import { Map } from "@mui/icons-material";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const useStyles = makeStyles(() => ({
    tableStyle: {
      border: "none",
      boxShadow: "none",
    },
    tableText: {
      fontFamily: "Open Sans",
      fontSize: "12px",
      lineHeight: "16px",
      textAlign: "center",
      color: '#6F6F6F',
      margin: "10px",
      padding: "6px",
    },
    tableHeader: {
      textAlign: "center",
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: "bold",
      color: '#6F6F6F',
      fontSize: "14px",
      lineHeight: "19px",
      padding: "6px",
    }
  }));

function ClientProfileAnalyst(props) {
    let decision_metadata = getProperMetadata(props.legal_person.analysis_status_events)
    let registration_data = decision_metadata.registration_data
    const { t } = useTranslation()
    const classes = useStyles();

    const boavistaEstimatedIncome = []
    if (decision_metadata.boavista) {
        if (decision_metadata.boavista.data && Array.isArray(decision_metadata.boavista.data)) {
            if (decision_metadata.boavista.data[0].revenue) {
                const max = decision_metadata.boavista.data[0].revenue.max
                const min = decision_metadata.boavista.data[0].revenue.min
                boavistaEstimatedIncome.push({max: max, min: min});
            }
        } else {
            for (let i = 0; i < Object.keys(decision_metadata.serasa).length; i++) {
                if ((decision_metadata.boavista[Object.keys(decision_metadata.boavista)[i]] || {}).data[0].revenue) {
                    const max = decision_metadata.boavista.data[0].revenue.max
                    const min = decision_metadata.boavista.data[0].revenue.min
                    boavistaEstimatedIncome.push({max: max, min: min});
                }
            }
        }
    }

    const [expandPhone, setExpandPhone]     = useState(1);
    const [expandEmail, setExpandEmail]     = useState(1);
    const [expandAddress, setExpandAddress] = useState(1);

    const renderExpansor = (value, setFunction) => {
        if (value === 1) return (
            <div onClick={setFunction} style={{ cursor: "pointer", padding: 0, marginTop: 12, display: "flex", height: "fit-content", flexDirection: "row", alignItems: "center"}}>
                <AddIcon style={{width: 24, height: 24, color: "grey"}} />
                <p style={{fontSize: 12, margin: 0, marginTop: 4, color: "grey"}}>Expandir</p>
            </div>
        )
        return (
            <div onClick={setFunction} style={{ cursor: "pointer", padding: 0, marginTop: 12, display: "flex", height: "fit-content", flexDirection: "row", alignItems: "center"}}>
                <RemoveIcon style={{width: 24, height: 24, color: "grey"}} />
                <p style={{fontSize: 12, margin: 0, marginTop: 4, color: "grey"}}>Minimizar</p>
            </div>
        )
    }

    const retrieveReceitaFederalData = (person) => {
        if (person && person.analysis_status_events) {
            if (Array.isArray(person.analysis_status_events) && person.analysis_status_events.length > 0) {
                for (let i = person.analysis_status_events.length - 1; i >= 0; i--) {
                    if (person.analysis_status_events[i] && person.analysis_status_events[i].decision_metadata) {
                        if (person.analysis_status_events[i].decision_metadata.receita_federal_status) {
                            return person.analysis_status_events[i].decision_metadata.receita_federal_status
                        }
                    }
                }
            }
        }
        return null;
    }

    const [receitaFederalData, setReceitaFederalData] = useState(null);

    useEffect(() => {
        setReceitaFederalData(retrieveReceitaFederalData(props.legal_person));
    }, [props.legal_person])

    const renderDataFields = () => {
        
        if (!registration_data || Object.keys(registration_data).length < 1) {
            return (
                <div style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <p style={{color: '#6f6f6f'}}>{t('no_bureau_data')}</p>
                </div>
            );
        }

        return (
            <div className="internalCardContainer" style={{ display: "flex", flexDirection: "column", gap: 8 }}>
                {registration_data.legal_name?.description &&
                        <DataField
                            title={"Razão Social"}
                            label={titleCase(registration_data.legal_name.description, "-")}
                            size={"normalMediumSize"}
                            titleNoWrap={true}
                            margin="5px"
                        />
                }
                {registration_data.trading_name?.description &&
                        <DataField
                            title={"Nome Fantasia"}
                            label={titleCase(registration_data.trading_name.description, "-")}
                            size={"normalMediumSize"}
                            titleNoWrap={true}
                            margin="5px"
                        />
                }
                {registration_data.foundation_date?.description &&
                        <DataField
                            title={"Data de Fundação"}
                            label={clientAge(registration_data.foundation_date.description) || "-"}
                            size={"normalMediumSize"}
                            titleNoWrap={true}
                            margin="5px"
                        />
                }
                {checkNested(registration_data, "tier", "flag") &&
                        <DataField
                            title={"Porte"}
                            label={registration_data.tier.description || "-"}
                            size={"normalMediumSize"}
                            titleNoWrap={true}
                            margin="5px"
                        />
                }
                {(registration_data.phone_number || []).length > 0 &&
                    <div style={{display: 'flex', flexDirection: 'row', gap: "16px"}}>
                        <p className="normalText" style={{width: "80px"}}>Telefones:</p>
                        <Paper className={classes.tableStyle} style={{width: 'fit-content', marginTop: "10px"}}>
                            <Table>
                                <TableBody>
                                    {registration_data.phone_number.map((phone, index) => (
                                        <>
                                            {expandPhone > index &&
                                                <TableRow className={'odd'} style={{border: 0}}>
                                                    <TableCell className={classes.tableText} style={{ textAlign: 'left', border: '0px'}}><div style={{padding: "0"}}>{formatPhone(phone)}</div></TableCell>
                                                </TableRow>
                                            }   
                                        </>
                                    ))}
                                </TableBody>
                            </Table>
                        </Paper>
                        {(registration_data.phone_number || []).length > 1 && renderExpansor(expandPhone, () => {
                            if (expandPhone !== 1) setExpandPhone(1)
                            else setExpandPhone((registration_data.phone_number || []).length)
                        })}
                    </div>
                }
                {(registration_data['e-mail'] || []).length > 0 &&
                    <div style={{display: 'flex', flexDirection: 'row', gap: "16px"}}>
                        <p className="normalText" style={{width: "80px"}}>Emails:</p>
                        <Paper className={classes.tableStyle} style={{width: 'fit-content', marginTop: "10px"}}>
                            <Table>
                                <TableBody>
                                    {registration_data['e-mail'].map((email, index) => (
                                        <>
                                            {expandEmail > index &&
                                                <TableRow className={'odd'} style={{border: 0}}>
                                                    <TableCell className={classes.tableText} style={{ textAlign: 'left', border: '0px'}}><div style={{padding: "0"}}>{email['email']}</div></TableCell>
                                                </TableRow>
                                            }
                                        </>
                                    ))}
                                </TableBody>
                            </Table>
                        </Paper>
                        {(registration_data['e-mail'] || []).length > 1 && renderExpansor(expandEmail, () => {
                            if (expandEmail !== 1) setExpandEmail(1)
                            else setExpandEmail((registration_data['e-mail'] || []).length)
                        })}
                    </div>
                }
                {registration_data?.address &&
                    <div style={{display: 'flex', flexDirection: 'row', gap: "16px"}}>
                        <p className="normalText" style={{width: "80px"}}>Endereços:</p>
                        <Paper className={classes.tableStyle} style={{width: 'fit-content', marginTop: "10px"}}>
                            <Table>
                                <TableBody>
                                    {registration_data.address.map((address, index) => (
                                        <>
                                            {expandAddress > index &&
                                                <TableRow className={'odd'} style={{border: 0}}>
                                                    <TableCell className={classes.tableText} style={{ textAlign: 'left', border: '0px'}}>{formatAddress(address, '-')}</TableCell>
                                                    <TableCell className={classes.tableText} style={{ textAlign: 'left', border: '0px'}}>
                                                        <Tooltip title={t('go_to_map')} placement="right">
                                                            <a
                                                                href={"https://www.google.com/maps/search/?api=1&query=" + formatAddress(address, "-").replace(/ /g, "%20").replace(/,/, '%2C')}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                <Map style={{verticalAlign: "middle", width: "20px", height:"20px",cursor:"pointer", color:"#777"}} />
                                                            </a>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            }
                                        </>
                                    ))}
                                </TableBody>
                            </Table>
                        </Paper>
                        {registration_data?.address.length > 1 && renderExpansor(expandAddress, () => {
                            if (expandAddress !== 1) setExpandAddress(1)
                            else setExpandAddress(registration_data?.address.length)
                        })}
                    </div>
                }
            </div>
        )
    }

    return (
        <div className="analysisCard" style={{height:"100%"}}>
            <div className={["blueText", "subtitleSize"].join(" ")}>{t("onboarding_data")} - CNPJ: {props.legal_person.document_number}</div>
            <div className="internalCardContainer" style={{display: "flex", flexDirection: "column", marginBottom: "0px"}}>
                {renderDataFields()}
            </div>
            {receitaFederalData &&
                <div>
                    {receitaFederalData.flag === 'negative' &&
                        <div className="normalText" style={{borderRadius: "8px", marginBottom: "8px", display: "flex", flexDirection: "row", gap: "8px", alignItems: "center"}}>
                            Situação na Receita federal: 
                            <Tooltip placement="right" title={retrieveReceitaFederalStatus(receitaFederalData.description)?.tooltip}>
                                <div style={{cursor: 'pointer', width: "fit-content", display: 'flex', backgroundColor: "#ff828255", color: "#a60505", flexDirection: 'row', padding: "4px 8px", borderRadius: 4, gap: 4, alignItems: 'center'}}>
                                    {retrieveReceitaFederalStatus(receitaFederalData.description)?.enum}
                                </div>
                            </Tooltip>
                        </div>
                    }
                    {receitaFederalData.flag === 'positive' &&
                        <div className="normalText" style={{borderRadius: "8px", marginBottom: "8px", display: "flex", flexDirection: "row", gap: "8px", alignItems: "center"}}>
                            Situação na Receita federal: 
                            <Tooltip placement="right" title={retrieveReceitaFederalStatus(receitaFederalData.description)?.tooltip}>
                                <div style={{cursor: 'pointer', width: "fit-content", display: 'flex', backgroundColor: "#6dd67d55", color: "#00c41d", flexDirection: 'row', padding: "4px 8px", borderRadius: 4, gap: 4, alignItems: 'center'}}>
                                    {retrieveReceitaFederalStatus(receitaFederalData.description)?.enum}
                                </div>
                            </Tooltip>
                        </div>
                    }
                </div>
            }
            {boavistaEstimatedIncome.length > 0 &&
                <div className="normalText" style={{borderRadius: "8px", marginTop: "8px", marginBottom: "8px", display: "flex", flexDirection: "row", gap: "8px", alignItems: "center"}}>
                    Receita presumida:
                    {boavistaEstimatedIncome[0].max && boavistaEstimatedIncome[0].min &&
                        <div style={{fontSize: "16px", width: "fit-content", display: 'flex', color: "#0b1f82", flexDirection: 'row', padding: "4px 8px", borderRadius: 4, gap: 4, alignItems: 'center'}}>
                            Entre {formatAsMoney(boavistaEstimatedIncome[0].min * 100)} e {formatAsMoney(boavistaEstimatedIncome[0].max * 100)}
                        </div>
                    }
                    {!boavistaEstimatedIncome[0].max && boavistaEstimatedIncome[0].min &&
                        <div style={{fontSize: "16px", width: "fit-content", display: 'flex', color: "#0b1f82", flexDirection: 'row', padding: "4px 8px", borderRadius: 4, gap: 4, alignItems: 'center'}}>
                            {formatAsMoney(boavistaEstimatedIncome[0].min * 100)}
                        </div>
                    }
                    {boavistaEstimatedIncome[0].max && !boavistaEstimatedIncome[0].min &&
                        <div style={{fontSize: "16px", width: "fit-content", display: 'flex', color: "#0b1f82", flexDirection: 'row', padding: "4px 8px", borderRadius: 4, gap: 4, alignItems: 'center'}}>
                            {formatAsMoney(boavistaEstimatedIncome[0].max * 100)}
                        </div>
                    }
                </div>
            }
        </div>
    );
}

export default ClientProfileAnalyst;
