import React from "react";
import moment from "moment";
import * as S from "./styles";

export default function FinalBeneficiariesCard(props) {
    const { metadata } = props

    const finalBeneficiaries = (metadata.final_beneficiaries.data || {}).final_beneficiaries
    const economicGroupParticipations = (metadata.final_beneficiaries.data || {}).economic_group_participations

    const empty = metadata.final_beneficiaries.data === null ||
                ((metadata.final_beneficiaries.data || {}).final_beneficiaries.length === 0 && (metadata.final_beneficiaries.data || {}).economic_group_participations.length === 0)

    const topicalize = (list) => {
        if (!list) return <></>
        return (
            <S.TopicWrapper>
                {list.map((item) => (
                    <S.Value>• {item}</S.Value>
                ))}
            </S.TopicWrapper>
        );
    }

    if (empty) {
        return (
            <S.Container>
                <S.Header>
                    <S.Title>Beneficiários Finais de Pessoa Jurídica</S.Title>
                </S.Header>
                <S.Message>Dados não encontrados</S.Message>
            </S.Container>
        );
    }

    return (
        <S.Container>
            <S.Header>
                <S.Title>Beneficiários Finais da Pessoa Jurídica</S.Title>
            </S.Header>
            {(finalBeneficiaries || []).length > 0 &&
                <>
                    <S.Subtitle>Beneficiários finais</S.Subtitle>
                    <S.ContentWrapper>
                        {finalBeneficiaries.map((beneficiary) => (
                            <>
                                <S.Item>
                                    {beneficiary.document_number &&
                                        <S.Line>
                                            <S.Label>Documento:</S.Label>
                                            <S.Value>{beneficiary.document_number}</S.Value>
                                        </S.Line>
                                    }
                                    <S.Line>
                                        <S.Label>Nome:</S.Label>
                                        <S.Value>{beneficiary.name}</S.Value>
                                    </S.Line>
                                    {beneficiary.participation_percentage !== 0 &&
                                        <S.Line>
                                            <S.Label>Participação:</S.Label>
                                            <S.Value>{beneficiary.participation_percentage}%</S.Value>
                                        </S.Line>
                                    }
                                    <S.Line style={{flex: 1}}>
                                        <S.Label>Associações:</S.Label>
                                        {topicalize(beneficiary.associated_persons)}
                                    </S.Line>
                                    {beneficiary.first_entry_date &&
                                        <S.Line>
                                            <S.Label>Data de ingresso:</S.Label>
                                            <S.Value>{moment(beneficiary.first_entry_date).format('DD/MM/YYYY')}</S.Value>
                                        </S.Line>
                                    }
                                </S.Item>
                            </>
                        ))}
                    </S.ContentWrapper>
                </>
            }
            {(economicGroupParticipations || []).length > 0 &&
                <>
                    <S.Subtitle>Participantes no grupo econômico</S.Subtitle>
                    <S.ContentWrapper>
                        {economicGroupParticipations.map((participant) => (
                            <>
                                <S.Item>
                                    {participant.document_number &&
                                        <S.Line>
                                            <S.Label>Documento:</S.Label>
                                            <S.Value>{participant.document_number}</S.Value>
                                        </S.Line>
                                    }
                                    <S.Line>
                                        <S.Label>Nome:</S.Label>
                                        <S.Value>{participant.name}</S.Value>
                                    </S.Line>
                                    <S.Line>
                                        <S.Label>Nível no grupo econômico:</S.Label>
                                        <S.Value>{participant.level}</S.Value>
                                    </S.Line>
                                    <S.Line>
                                        <S.Label>Tipo:</S.Label>
                                    <S.Value>{participant.participation}</S.Value>
                                    </S.Line>
                                    {participant.tax_status &&
                                        <S.Line>
                                            <S.Label>Situação fiscal:</S.Label>
                                            <S.Value>{participant.tax_status}</S.Value>
                                        </S.Line>
                                    }
                                    {participant.participation_percentage !== 0 &&
                                        <S.Line>
                                            <S.Label>Participação:</S.Label>
                                            <S.Value>{participant.participation_percentage}%</S.Value>
                                        </S.Line>
                                    }
                                    {participant.cumulative_participation_percentage !== 0 &&
                                        <S.Line>
                                            <S.Label>Participação acumulada:</S.Label>
                                            <S.Value>{participant.cumulative_participation_percentage}%</S.Value>
                                        </S.Line>
                                    }
                                    {participant.entry_date &&
                                        <S.Line>
                                            <S.Label>Data de ingresso:</S.Label>
                                            <S.Value>{moment(participant.entry_date).format('DD/MM/YYYY')}</S.Value>
                                        </S.Line>
                                    }
                                </S.Item>
                            </>
                        ))}
                    </S.ContentWrapper>
                </>
            }
        </S.Container>
    );
}
