import React, {useEffect,useCallback, useContext, useReducer, useRef} from "react";
import {dataFetchReducer} from "../../Utils/requestUtils"
import { CircularProgress } from "@material-ui/core";
import axios from "axios";
import ErrorBoundary from "../../Utils/ErrorBoundary"
import {useHistory} from 'react-router-dom'
import AuthContext from '../../../context/auth-context'
import ReservationCard from "../ReservationsInbox/ReservationCard/ReservationCard"
import RentalAgreementCard from "../RentalAgreementsInbox/RentalAgreementCard/RentalAgreementCard";
import Counter from "../Counter/counter";

function CarRentalInbox(props) {

	
    let history = useHistory()
    let user_data = useContext(AuthContext).user_data

	if (!(["read_car_rental_reservations", "rental_agreements_viewer"].some(role => user_data.roles.includes(role)))){ 
        history.push("")
    }

    const [pageState, dispatchPageState] = useReducer(
        dataFetchReducer,
        {fetchedData: null, isLoading: true, isError: false} 
    )

    const doRequest = useCallback(
        (payload, isRoutine) => {
            if (!isRoutine){
                dispatchPageState({type: "data_fetch_init"})  
            }
            axios.get("/dash/car_rental/pending_items", { 
                params: payload
            }).then(response=>{
                dispatchPageState({
                    type: "data_fetch_success",
                    payload: response.data 
                })          
            }).catch(error => {
                if ((error.response || {}).status === 403) dispatchPageState({type: "data_fetch_failure_403"})
                else if ((error.response || {}).status === 404) dispatchPageState({type: "data_fetch_failure_404"})  
                else dispatchPageState({type: "data_fetch_failure"})    
            })
        },[]
    )

	const timerRef = useRef(null);
    useEffect(() => {
		const startInterval = (payload) => {
            stopInterval()

			timerRef.current = setInterval(() => {
			  doRequest(payload, true);
			}, 15000);
		};

		const stopInterval = () => {
            clearInterval(timerRef.current)
        }

		let payload = {
            "limit": 25
        };

		const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
				startInterval(payload)
			} else {
				stopInterval()
			}
		};
		document.addEventListener('visibilitychange', handleVisibilityChange);


        const initial_delay_timeout = setTimeout(()=> {
            doRequest(payload, false)
        }, 300);

		startInterval(payload)
        
		return () => {
            clearTimeout(initial_delay_timeout)
            stopInterval()
			document.removeEventListener('visibilitychange', handleVisibilityChange);
		}	
    },[doRequest])

	const contentContainer = (dataList) => {

		let nr_of_cards = dataList['data'].length
		let cards = dataList['data'].map((car_rental_list) => (
			car_rental_list.item_type === "reservation" 
			? <ErrorBoundary key={car_rental_list.reservation_key}> 
				<ReservationCard reservation={car_rental_list} />
			</ErrorBoundary>
			: <ErrorBoundary key={car_rental_list.rental_agreement_key}> 
				<RentalAgreementCard rental_agreement={car_rental_list} />
			</ErrorBoundary>
		))

		if (nr_of_cards === 0) {
			return (
			<div className='emptyContainer'>
				<p className={["blueText", "subtitleSize"].join(" ")}>
					Nenhum aluguel ou reserva a ser analisado
				</p>
			</div>
			)
		}

		else {
			return <div className='cardContainer'>{cards}</div>
		}
	}

	if (pageState.fetchedData) {
		return (
			<div style={{ width: "auto", paddingBottom: "30px"}}>
				<div style={{display:"flex"}}>
                    <div>
                        <p className={["blueText", "titleSize"].join(" ")}>ALUGUÉIS E RESERVAS</p>
                    </div>
                </div>
				<div style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center", marginBottom: 16}}>
					<Counter />
				</div>
			<div><ErrorBoundary>{contentContainer(pageState.fetchedData)}</ErrorBoundary></div>
		</div>
		);
	}
	else if (pageState.isError){
		return (
			<div style={{ width: "auto" }}>
				<div style={{display:"flex"}}>
					<div >
						<p className={["blueText", "titleSize"].join(" ")}>ALUGUÉIS E RESERVAS</p>
					</div>	
				</div>			
                <div style={{height: "55vh", display:"flex"}}>
                    <p className={["blueText", "titleSize"].join(" ")} style={{margin:"auto", textAlign:"center"}}>
                        {pageState.errorMessage}
                    </p>
                </div>
			</div>
		)
	}
	else if (pageState.isLoading) {
		return(
			<div style={{ width: "auto" }}>
				<p className={["blueText", "titleSize"].join(" ")}>ALUGUÉIS E RESERVAS</p>
				<div className="circularProgressCenter">
					<CircularProgress />
				</div>
			</div>
		)
    }
    else return null
	
	}

export default CarRentalInbox;