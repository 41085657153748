import React, { useEffect, useContext, useReducer, useState, useCallback } from "react";
import { dataFetchReducer } from "../../Utils/requestUtils";
import AuthContext from "../../../context/auth-context"
import { useParams } from "react-router-dom";
import axios from "axios";
import {CircularProgress } from '@material-ui/core'
import ClientProfile from "./ClientProfile/ClientProfile";
import ClientProfileAnalyst from "./ClientProfile/ClientProfileAnalyst";
import LegacyClientProfile from "./ClientProfile/legacy/ClientProfile";
import LegacyClientProfileAnalyst from "./ClientProfile/legacy/ClientProfileAnalyst";
import AnalystSnackBar from "./AnalystSnackBar/AnalystSnackBar"
import CNHOCRCard from "../Cards/CNHOCRCard/CNHOCRCard";
import RGOCRCard from "../Cards/RGOCRCard/RGOCRCard";
import CNHProfile from "./CNHProfile/CNHProfile";
import ProcessesCard from "./Cards/ProcessesCard/ProcessesCard"
import FaceReconCard from "../Cards/FaceReconCard/FaceReconCard"
import Summary from "./Summary/Summary";
import Indicators from "./Indicators/Indicators"
import DeviceScan from "./DeviceScan/DeviceScan"
import PeopleRelatedCard from "./Cards/PeopleRelatedCard/PeopleRelatedCard"
import CompaniesRelatedCard from "./Cards/CompaniesRelatedCard/CompaniesRelatedCard"
import MediaCard from "./Cards/MediaCard/MediaCard"
import ProcessesEDPCard from "../../../components/Cards/ProcessesEDP";
import DAFNCard from "./Cards/DAFNCard/DAFNCard"
import IbamaCard from "./Cards/IbamaCard/IbamaCard"
import ProtestsCard from "./Cards/ProtestsCard/ProtestsCard"
import SlaveryCard from "./Cards/SlaveryCard/SlaveryCard"
import OFACCard from "./Cards/OFACCard/OFACCard"
import UNSCCard from "./Cards/UNSCCard/UNSCCard"
import PEPCard from "./Cards/PEPCard/PEPCard"
import PEPRelatedCard from "./Cards/PEPRelatedCard/PEPRelatedCard"
import CriminalRecordsCard from "./Cards/CriminalRecordsCard/CriminalRecordsCard"
import BACENCard from "./Cards/BACENCard/BACENCard"
import ArrestWarrantCard from "./Cards/ArrestWarrantCard/ArrestWarrantCard"
import CNJIneligibilityCard from "./Cards/CNJIneligibilityCard/CNJIneligibilityCard"
import CVMCard from "./Cards/CVMCard/CVMCard"
import FBICard from "./Cards/FBICard/FBICard"
import InterpolCard from "./Cards/InterpolCard/InterpolCard"
import IRCard from "./Cards/IRCard/IRCard"
import RiskAddressCard from "./Cards/RiskAddressCard/RiskAddressCard"
import UKCard from "./Cards/UKCard/UKCard"
import EUCard from "./Cards/EUCard/EUCard"
import RufraCard from "./Cards/RufraCard/RufraCard"
import WorldBankCard from "./Cards/WorldBankCard/WorldBankCard"
import PartnershipCard from "./Cards/PartnershipCard/PartnershipCard"
import CustomDataCard from "../Cards/CustomDataCard/CustomDataCard"
import Observation from "./Observation/Observation"
import moment from 'moment'
import ErrorBoundary from '../../Utils/ErrorBoundary'
import {useHistory} from 'react-router-dom'
import {getProperMetadata, checkNested} from "../utils/utils"
import History from "./History/History";
import PDFDownloadErrorDialog from "../utils/PDFDownload/PDFDownloadErrorDialog/PDFDownloadErrorDialog"
import PDFDownloadButton from "../utils/PDFDownload/PDFDownloadButton/PDFDownloadButton"
import PublicEmployeeCard from "./Cards/PublicEmployeeCard/PublicEmployeeCard";
import ElectoralDonorsCard from "./Cards/ElectoralDonorsCard/ElectoralDonorsCard";
import SocialAssistanceCard from "./Cards/SocialAssistanceCard/SocialAssistanceCard";
import CNDTCard from "./Cards/CNDTCard/CNDTCard";
import CNDFCard from "./Cards/CNDFCard/CNDFCard";
import LavaJatoCard from "./Cards/LavaJatoCard/LavaJatoCard";
import MilitaryRegistryCard from "../Cards/MilitaryRegistryCard/MilitaryRegistryCard";
import ClassEntityRegistryCard from "../Cards/ClassEntityRegistryCard/ClassEntityRegistryCard";
import NationalRegistryOfForeignersCard from "../Cards/NationalRegistryOfForeignersCard/NationalRegistryOfForeignersCard";
import LegacyCallout from "../../../components/legacyCallout/LegacyCallout";
import AdministrativeSanctionsCard from "./Cards/AdministrativeSanctionsCard";

function NaturalPerson(props) {
    let history = useHistory()
    let user_data = useContext(AuthContext).user_data
	const [legacy, setLegacy] = useState(false);
	const LegacyText = "Essa secção foi recentemente aprimorada para melhorar a visualização dos dados. Em breve, essa atualização será implementada permanentemente."    
    if (
			!user_data.roles.includes("read_onboarding_natural_persons")&&
			!user_data.roles.includes("card_issuance_transaction_read_onboarding_natural_person_by_key")&&
			!user_data.roles.includes("card_issuance_alert_read_onboarding_natural_person_by_key")
		) {
			history.push("")
    }
    
	let { natural_person_key } = useParams();

    const [currentAnalyst, setCurrentAnalyst] = useState(null)

    const [natural_person, dispatchNaturalPerson] = useReducer(
        dataFetchReducer,
        {fetchedData: null, isLoading: true, isError: false} 
    )
	const [pdfErrorDialogOpen, setPDFErrorDialogOpen] = useState(false)

	const [pdfErrorDialogType, setPDFErrorDialogType] = useState("")
    const [snackBarOpen, setSnackBarOpen] = useState(true)

	const handlePDFErrorDialogOpen = (errorType) => {
		setPDFErrorDialogType(errorType)
		setPDFErrorDialogOpen(true)
	};
	
	const handlePDFErrorDialogClose = () => {
		setPDFErrorDialogType("")
		setPDFErrorDialogOpen(false)
	};
	
    useEffect(() => {
		dispatchNaturalPerson({type: "data_fetch_init"})
		const timer = setTimeout(() => {
			axios.get('/dash/onboarding/natural_person/' + natural_person_key).then( response => {
                dispatchNaturalPerson({
                    type: "data_fetch_success",
                    payload: response.data
                })   
			}).catch(error => {
                if ((error.response || {}).status === 403) dispatchNaturalPerson({type: "data_fetch_failure_403"})
                else if ((error.response || {}).status === 404) dispatchNaturalPerson({type: "data_fetch_failure_404"})  
                else dispatchNaturalPerson({type: "data_fetch_failure"})    
			})	
		}, 500);
		return () => {
			clearTimeout(timer)
        }
    }, [natural_person_key])

	const analystHeartBeat = useCallback(
        () => {
			if (natural_person.fetchedData) {
				if (user_data.roles.includes("onboarding_analyst_heartbeat") && ["in_manual_analysis"].includes(natural_person.fetchedData.analysis_status)) {
					let payload = {}
					axios.put('/dash/onboarding/natural_person/' + natural_person_key + "/analyst_heartbeat", payload).then(response => {
						setCurrentAnalyst(response.data.analyst)
					}).catch(error => { console.log(error) })
				}
			}
        }, [natural_person.fetchedData, user_data.roles, natural_person_key]
    )

	useEffect(() => {
		analystHeartBeat(true)
		const timer = setInterval(() => {
			analystHeartBeat(true)
		}, 15000)
		return () => {
			clearInterval(timer)
			analystHeartBeat(false)
		}
    }, [analystHeartBeat])

 	if (natural_person.fetchedData) {
        let decision_metadata = getProperMetadata(natural_person.fetchedData.analysis_status_events)
        
		let hasAutomaticDecision = (natural_person.fetchedData.analysis_status === "automatically_approved" || natural_person.fetchedData.analysis_status === "automatically_reproved" ) 
		let showObservationCard = !hasAutomaticDecision

		const selectProcessesTable = (registration_date, decision_metadata) => {
			if (!(decision_metadata || {}).processes) return <></>
			const date = new Date(registration_date);
			const index_creation_date = new Date('2024-06-09T20:39:10Z');
			if (date.getTime() > index_creation_date.getTime()) {
				return (
					<div className="analysisCardContainer">
						<ErrorBoundary>
							<ProcessesEDPCard person_type={'natural_person'} person_key={natural_person_key} consolidated={decision_metadata.processes} company_key={natural_person.fetchedData?.company_key}/>
						</ErrorBoundary>
					</div> 
				);
			}

			return (
				<div className="analysisCardContainer">
					<ErrorBoundary>
						<ProcessesCard processes={decision_metadata.processes}/>
					</ErrorBoundary>
				</div> 
			);
		}
	

		const showCard = {
			partnership_card: checkNested(natural_person.fetchedData,"partnership_key"),
			rg_ocr_card: checkNested(natural_person.fetchedData,"documents","rg","ocr_front_key")||checkNested(natural_person.fetchedData,"documents","rg","ocr_back_key"),
			face_recon: checkNested(natural_person.fetchedData,"face","registration_key"),
			cnh_ocr_card: checkNested(natural_person.fetchedData,"documents","cnh","ocr_key")||checkNested(natural_person.fetchedData,"documents","cnh","ocr_front_key")||checkNested(natural_person.fetchedData,"documents","cnh","ocr_back_key"),
			cnh_profile: !!decision_metadata&&checkNested(decision_metadata,"cnh_database"),
			device_scan: checkNested(natural_person.fetchedData, "source", "session_id"),
			military_registry_ocr_card: checkNested(natural_person.fetchedData,"documents","military_registry","ocr_key"),
			class_entity_registry_ocr_card: checkNested(natural_person.fetchedData,"documents","class_entity_registry","ocr_key"),
			national_registry_of_foreigners_ocr_card: checkNested(natural_person.fetchedData,"documents","national_registry_of_foreigners","ocr_front_key")
		}
	
		let inputDataWidth = "50%"
		let outputDataWidth = "50%"
		if (showCard.rg_ocr_card||showCard.cnh_ocr_card||showCard.cnh_profile) {
			inputDataWidth = "60%"
			outputDataWidth = "40%"
		}

		return(
            <div style={{minWidth: "900px", overflow: "hidden", paddingBottom: "30px"}}>
                <div className="analysisCardContainer" style={{display:"flex"}}>
                    <ErrorBoundary>                    
                        <div>
                            <div className={["blueText", "titleSize"].join(" ")} style={{margin:"5px 0px"}}>
                                Análise {Object.keys(decision_metadata || {}).length === 0 ? 
                                'pendente no sistema' : (decision_metadata || {}).rule_identifier} - Pessoa Física 
                                {user_data.business_group_key == null ? null : "- " + natural_person.fetchedData.company_name}
                            </div>
                            <div className={["labelText", "subtitleSize"].join(" ")} style={{margin:"5px 0px", display: 'flex', flexDirection: "column"}}>
                                Cliente {natural_person.fetchedData.analyst_request === true ? 
                                    "requisitado por " + (natural_person.fetchedData.analysis_status_events || [{}])[0].analyst_name  + " no dia " + moment(natural_person.fetchedData.registration_date).format("DD/MM/YYYY")  + " às " + moment((natural_person.fetchedData.analysis_status_events || [{}])[0].event_date).format("HH:mm") :
                                    "#" + natural_person.fetchedData.id  + " registrado no dia " + moment(natural_person.fetchedData.registration_date).format("DD/MM/YYYY") + " às " + moment(natural_person.fetchedData.registration_date).format("HH:mm")}
									{" e analisado em " + moment(natural_person.fetchedData.created_at).format("DD/MM/YYYY") + " às " + moment(natural_person.fetchedData.created_at).format("HH:mm")}
								{natural_person.fetchedData.client_category && <span>Categoria: {natural_person.fetchedData.client_category}</span>}	
							</div>
                        </div>
						{user_data.roles.includes("read_pdf_onboarding") ? 
							<div style={{margin: "auto 50px auto auto"}}>
                            	<PDFDownloadButton personKey={natural_person_key} person={natural_person.fetchedData} type={'natural'}
                                            dashAPIEndpointUrl={"/dash/onboarding/natural_person"}
                                            handlePDFDownloadErrorDialogOpenClick={handlePDFErrorDialogOpen}/>
                        	</div>
						:
						null}
                    </ErrorBoundary>
                </div>
            <div style={{display: "flex", margin: "10px 0px"}}>
            <div style={{width: inputDataWidth, display:"flex", flexDirection:"column"}}>
				{showCard.partnership_card&&
					<div style={{flexGrow: "1"}} className="analysisCardContainer">
						<ErrorBoundary>
							<PartnershipCard partnership_key={natural_person.fetchedData.partnership_key}/>
						</ErrorBoundary>
						</div>}
				{legacy ? showCard.face_recon ?
						<div className="analysisCardContainer" style={{display: 'flex', flexDirection: 'column'}}>
							<LegacyCallout legacy={legacy} setLegacy={setLegacy} text={LegacyText}/>
							<div className="analysisCardContainer" style={{display:"grid", gridTemplateColumns: "50% 50%", margin: 0}}>
								<div style={{marginRight:"10px"}}>
									<ErrorBoundary>
										<FaceReconCard
											name={"client_selfie"}
											registration_key={natural_person.fetchedData.face.registration_key}
										/>
									</ErrorBoundary>
								</div>
								<div style={{marginLeft:"10px"}}>
								<ErrorBoundary>
									{natural_person.fetchedData.analyst_request === true ?
									<LegacyClientProfileAnalyst natural_person={natural_person.fetchedData}/> :						
									<LegacyClientProfile natural_person={natural_person.fetchedData}/>}
								</ErrorBoundary>
								</div>
							</div>
						</div>
						:
						<div className="analysisCardContainer" style={{flexGrow: "1", display: 'flex', flexDirection: 'column'}}>
							<LegacyCallout legacy={legacy} setLegacy={setLegacy} text={LegacyText}/>
							<ErrorBoundary>
								{natural_person.fetchedData.analyst_request === true ?
								<LegacyClientProfileAnalyst natural_person={natural_person.fetchedData}/> :						
								<LegacyClientProfile natural_person={natural_person.fetchedData}/>}
							</ErrorBoundary>
						</div>		
						:
						<div className="analysisCardContainer" style={{flexGrow: "1", display: 'flex', flexDirection: 'column'}}>
							<LegacyCallout legacy={legacy} setLegacy={setLegacy} text={LegacyText}/>
							<ErrorBoundary>
								{natural_person.fetchedData.analyst_request === true ?
								<ClientProfileAnalyst natural_person={natural_person.fetchedData}/> :
								<ClientProfile natural_person={natural_person.fetchedData}/>}
							</ErrorBoundary>
						</div>
					}
				{showCard.cnh_ocr_card&&
				<div className="analysisCardContainer" style={{flexGrow: "1"}}>
				<ErrorBoundary>
					<CNHOCRCard cnh= {natural_person.fetchedData.documents.cnh} decision_metadata = {decision_metadata}/>
				</ErrorBoundary>
				</div>}
				{showCard.rg_ocr_card&&
				<div className="analysisCardContainer" style={{flexGrow: "1"}}>
				<ErrorBoundary>
					<RGOCRCard rg= {natural_person.fetchedData.documents.rg} decision_metadata = {decision_metadata}/>
				</ErrorBoundary>
				</div>}
				{showCard.military_registry_ocr_card&&
				<div className="analysisCardContainer" style={{flexGrow: "1"}}>
				<ErrorBoundary>
					<MilitaryRegistryCard military_registry= {natural_person.fetchedData.documents.military_registry}/>
				</ErrorBoundary>
				</div>}
				{showCard.class_entity_registry_ocr_card&&
				<div className="analysisCardContainer" style={{flexGrow: "1"}}>
				<ErrorBoundary>
					<ClassEntityRegistryCard class_entity_registry= {natural_person.fetchedData.documents.class_entity_registry}/>
				</ErrorBoundary>
				</div>}
				{showCard.national_registry_of_foreigners_ocr_card&&
				<div className="analysisCardContainer" style={{flexGrow: "1"}}>
				<ErrorBoundary>
					<NationalRegistryOfForeignersCard national_registry_of_foreigners={natural_person.fetchedData.documents.national_registry_of_foreigners}/>
				</ErrorBoundary>
				</div>}
				{showCard.cnh_profile ?
				<div className="analysisCardContainer" style={{flexGrow: "1"}}>
				<ErrorBoundary>
					<CNHProfile natural_person={natural_person.fetchedData}/>
				</ErrorBoundary>
				</div> : null}
				{showCard.device_scan ?
				<div style={{flexGrow: "1", flexDirection: "column"}} className="analysisCardContainer">
					<ErrorBoundary>
						<DeviceScan natural_person={natural_person.fetchedData}/>
					</ErrorBoundary>
				</div>	: null}
				{natural_person.fetchedData.custom_data&&
				<div className="analysisCardContainer" style={{flexGrow: "1"}}>
					<ErrorBoundary>
						<CustomDataCard data={natural_person.fetchedData} />
					</ErrorBoundary>
				</div>}
			</div>
                    <div style={{width: outputDataWidth, display:"flex", flexDirection:"column"}}>
                        <div style={{flexGrow: "1"}} className="analysisCardContainer">
                            <ErrorBoundary>
                                <Summary natural_person={natural_person.fetchedData}/>
                            </ErrorBoundary>
                        </div>
                        {((decision_metadata || {}).indicators || []).length > 0 ?
                        <div style={{flexGrow: "1"}} className="analysisCardContainer">
                            <ErrorBoundary>
                                <Indicators indicators={decision_metadata.indicators}/>
                            </ErrorBoundary>
                        </div>	: null}
                    </div>
				</div>
               
				{ decision_metadata && decision_metadata.checker_rufra_data ?
                <div className="analysisCardContainer" style={{flexGrow: "1"}}>
                    <ErrorBoundary>
                        <RufraCard rufra_data={decision_metadata.checker_rufra_data}/>
                    </ErrorBoundary>
                </div>
                : null}

                <div>
                    <div className="analysisCardContainer">
                        <ErrorBoundary>
                            <History naturalPerson={natural_person.fetchedData}/>
                        </ErrorBoundary>
                    </div>
                </div>
				<div>
					{(decision_metadata || {}).related_people ? 
					<div className="analysisCardContainer">
						<ErrorBoundary>
							<PeopleRelatedCard related_people={decision_metadata.related_people}/>
						</ErrorBoundary>
					</div> : null} 
					{(decision_metadata || {}).related_companies ? 
					<div className="analysisCardContainer">
						<ErrorBoundary>
							<CompaniesRelatedCard related_companies={decision_metadata.related_companies}/>
						</ErrorBoundary>
					</div> : null} 
					{(decision_metadata || {}).social_assistance &&
					<div className="analysisCardContainer">
						<ErrorBoundary>
							<SocialAssistanceCard social_assistance={decision_metadata.social_assistance}/>
						</ErrorBoundary>
					</div>
					}
					{(decision_metadata || {}).lava_jato ? 
					<div className="analysisCardContainer">
						<ErrorBoundary>
							<LavaJatoCard lava_jato={decision_metadata.lava_jato}/>
						</ErrorBoundary>
					</div> : null} 
					{(decision_metadata || {}).electoral_donors &&
					<div className="analysisCardContainer">
						<ErrorBoundary>
							<ElectoralDonorsCard electoral_donors={decision_metadata.electoral_donors}/>
						</ErrorBoundary>
					</div>
					}
					{(decision_metadata || {}).public_employee &&
					<div className="analysisCardContainer">
						<ErrorBoundary>
							<PublicEmployeeCard public_employee={decision_metadata.public_employee}/>
						</ErrorBoundary>
					</div>
					}
                    {(decision_metadata || {}).media_exposure ?
					<div className="analysisCardContainer">
						<ErrorBoundary>
							<MediaCard media_exposure={decision_metadata.media_exposure}/>
						</ErrorBoundary>
					</div> : null}
                    {selectProcessesTable(natural_person.fetchedData.registration_date, decision_metadata)}
					{((decision_metadata || {}).ceaf || (decision_metadata || {}).cepim || (decision_metadata || {}).ceis || (decision_metadata || {}).cnep) &&
						<ErrorBoundary>
							<AdministrativeSanctionsCard cepim={(decision_metadata || {}).cepim} ceis={(decision_metadata || {}).ceis} cnep={(decision_metadata || {}).cnep} ceaf={(decision_metadata || {}).ceaf}/>
						</ErrorBoundary>
					}
                    {(decision_metadata || {}).dafn ?
					<div className="analysisCardContainer">
						<ErrorBoundary>
							<DAFNCard dafn={decision_metadata.dafn}/>
						</ErrorBoundary>
					</div> : null}
                    {(decision_metadata || {}).ibama ?
					<div className="analysisCardContainer">
						<ErrorBoundary>
							<IbamaCard ibama={decision_metadata.ibama}/>
						</ErrorBoundary>
					</div> : null}
                    {(decision_metadata || {}).protests ?
					<div className="analysisCardContainer">
						<ErrorBoundary>
							<ProtestsCard protests={decision_metadata.protests}/>
						</ErrorBoundary>
					</div> : null}
                    {(decision_metadata || {}).slavery ?
					<div className="analysisCardContainer">
						<ErrorBoundary>
							<SlaveryCard slavery={decision_metadata.slavery}/>
						</ErrorBoundary>
					</div> : null}
                    {(decision_metadata || {}).ofac ?
					<div className="analysisCardContainer">
						<ErrorBoundary>
							<OFACCard ofac={decision_metadata.ofac}/>
						</ErrorBoundary>
					</div> : null}
                    {(decision_metadata || {}).unsc ?
					<div className="analysisCardContainer">
						<ErrorBoundary>
							<UNSCCard unsc={decision_metadata.unsc}/>
						</ErrorBoundary>
					</div> : null}
                    {(decision_metadata || {}).pep ?
					<div className="analysisCardContainer">
						<ErrorBoundary>
							<PEPCard pep={decision_metadata.pep}/>
						</ErrorBoundary>
					</div> : null}
                    {(decision_metadata || {}).related_pep ?
					<div className="analysisCardContainer">
						<ErrorBoundary>
							<PEPRelatedCard related_pep={decision_metadata.related_pep}/>
						</ErrorBoundary>
					</div> : null}
                    {(decision_metadata || {}).criminal_records ?
					<div className="analysisCardContainer">
						<ErrorBoundary>
							<CriminalRecordsCard criminal_records={decision_metadata.criminal_records}/>
						</ErrorBoundary>
					</div> : null}
                    {(decision_metadata || {}).bacen ?
					<div className="analysisCardContainer">
						<ErrorBoundary>
							<BACENCard bacen={decision_metadata.bacen}/>
						</ErrorBoundary>
					</div> : null}
					{((decision_metadata || {}).arrest_warrant || {}).data ?
					<div className="analysisCardContainer">
						<ErrorBoundary>
							<ArrestWarrantCard arrest_warrant={decision_metadata.arrest_warrant}/>
						</ErrorBoundary>
					</div> : null}
                    {(decision_metadata || {}).cnj_ineligibility ?
					<div className="analysisCardContainer">
						<ErrorBoundary>
							<CNJIneligibilityCard cnj_ineligibility={decision_metadata.cnj_ineligibility}/>
						</ErrorBoundary>
					</div> : null}
                    {(decision_metadata || {}).cvm ?
					<div className="analysisCardContainer">
						<ErrorBoundary>
							<CVMCard cvm={decision_metadata.cvm}/>
						</ErrorBoundary>
					</div> : null}
                    {(decision_metadata || {}).fbi ?
					<div className="analysisCardContainer">
						<ErrorBoundary>
							<FBICard fbi={decision_metadata.fbi}/>
						</ErrorBoundary>
					</div> : null}
                    {(decision_metadata || {}).interpol ?
					<div className="analysisCardContainer">
						<ErrorBoundary>
							<InterpolCard interpol={decision_metadata.interpol}/>
						</ErrorBoundary>
					</div> : null}
                    {(decision_metadata || {}).risk_address ?
					<div className="analysisCardContainer">
						<ErrorBoundary>
							<RiskAddressCard risk_address={decision_metadata.risk_address}/>
						</ErrorBoundary>
					</div> : null}
                    {((decision_metadata || {}).receita_federal_status || {}).fiscal_situation ?
					<div className="analysisCardContainer">
						<ErrorBoundary>
							<IRCard receita_federal_status={decision_metadata.receita_federal_status}/>
						</ErrorBoundary>
					</div> : null}
					{(decision_metadata || {}).uk_sanctions ?
					<div className="analysisCardContainer">
						<ErrorBoundary>
							<UKCard unitedKingdom={decision_metadata.uk_sanctions}/>
						</ErrorBoundary>
					</div> : null}
					{(decision_metadata || {}).eu_sanctions ?
					<div className="analysisCardContainer">
						<ErrorBoundary>
							<EUCard europeUnion={decision_metadata.eu_sanctions}/>
						</ErrorBoundary>
					</div> : null}
					{(decision_metadata || {}).world_bank ?
					<div className="analysisCardContainer">
						<ErrorBoundary>
							<WorldBankCard worldBank={decision_metadata.world_bank}/>
						</ErrorBoundary>
					</div> : null}
                    {(decision_metadata || {}).cndt ?
					<div className="analysisCardContainer">
						<ErrorBoundary>
							<CNDTCard cndt={decision_metadata.cndt}/>
						</ErrorBoundary>
					</div> : null}
                    {(decision_metadata || {}).cndf ?
					<div className="analysisCardContainer">
						<ErrorBoundary>
							<CNDFCard cndf={decision_metadata.cndf}/>
						</ErrorBoundary>
					</div> : null}
					{showObservationCard && 
						<div className="analysisCardContainer">
							<ErrorBoundary>
								<Observation natural_person={natural_person.fetchedData} natural_person_key={natural_person_key} user_data={user_data}/>
							</ErrorBoundary>
						</div>}
					{["in_manual_analysis"].includes(natural_person.fetchedData.analysis_status) && user_data.roles.includes("onboarding_analyst_heartbeat") && currentAnalyst != null ?
						<AnalystSnackBar
							currentAnalyst={currentAnalyst}
							open={snackBarOpen}
							onClose={() => setSnackBarOpen(false)}
						/> : null}
				</div>
				<PDFDownloadErrorDialog open={pdfErrorDialogOpen}
										onClose={handlePDFErrorDialogClose}
										errorType={pdfErrorDialogType}/>
			</div>
		);
	}
    else if (natural_person.isLoading) {
		return(
			<div style={{ width: "auto" }}>
				<div className='circularProgressCenter'>
					<CircularProgress />
				</div>
			</div>
		)
    }
	else if(natural_person.isError){
		return (
            <div style={{height: "80vh", display:"flex"}}>
                <p className={["blueText", "titleSize"].join(" ")} style={{margin:"auto", textAlign:"center"}}>
                    {natural_person.errorMessage}
                </p>
            </div>
		)
	}
  
  return null;
}

export default NaturalPerson;
