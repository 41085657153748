import React, { useState, useCallback, useContext } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import ErrorBoundary from "../../../Utils/ErrorBoundary";
import { useTranslation } from "react-i18next";
import AuthContext from "../../../../context/auth-context";

function DeleteWebhookDialog(props) {
  let { user_data } = useContext(AuthContext);
  const { t } = useTranslation();
  let {open, title} = props

  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const exitDialog = () => {
    setAlertOpen(false);
    setInputValue('');
    setIsButtonDisabled(true);
    props.onClose();
  }

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    setIsButtonDisabled(value !== "Delete Me");
  };

  const deactivateWebhookRequest = useCallback(
    (payload) => {
      let headers = {};
      if (user_data.business_group_key) {
        headers = {
          "COMPANY-KEY": props.company_key
        }
      }
      axios.delete(`/dash/webhook/${props.service}`,{
        params: payload,
        headers: headers
      }).then(response=>{
        setAlertMessage(t("deactivated_webhook_success"));
        setAlertOpen(true);
        setTimeout(() => {
          setAlertOpen(false);
          exitDialog();
        }, 1000);
      }).catch(error => {
        setAlertMessage(t("deactivated_webhook_error"));
        setAlertOpen(true);
        setTimeout(() => setAlertOpen(false), 3000);
      })
      // eslint-disable-next-line
    },[]
  )

  const deleteWebhook = () => {
    const payload = {}
    const timer_ = setTimeout(() => {
        deactivateWebhookRequest(payload)
    }, 300);
    return () => {
      clearTimeout(timer_)
    }
  }

  return (
    <div>
      <Dialog
      open={open}
      onClose={exitDialog}
      keepMounted={false}
      >
      <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", marginRight:"24px"}}>
        <DialogTitle style={{paddingBottom: "0px"}} className={["blueText", "normalMediumSize"].join(" ")}>{t(title)}</DialogTitle>
        <IconButton size="small" onClick={exitDialog}>
          <CloseIcon fontSize="small"/> {t("exit")}
        </IconButton >
      </div>
      <DialogContent >
          <div style={{height: "220px", marginTop: "30px", textAlign:"center"}}>
            <ErrorBoundary doNothing><div className={["normalText", "bold"].join(" ")}>{t("deactivate_webhook_text")}</div></ErrorBoundary>
            <div
              className={["listCardItem", "bold", "normalSmallSize"].join(" ")}
              style={{flex:"1 1 100px", textAlign:"center", marginTop: 20}}
            >
              {t("type")} <em style={{ margin: '0 10px' }}> Delete Me </em> {t("to_continue")}
            </div>
            <input
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              placeholder={t("type_delection_placeholder")}
              style={{
                width: "100%",
                padding: "10px",
                margin: "20px 0 20px 0",
                borderRadius: "4px",
                border: "1px solid #ccc",
              }}
            />
            <div style={{display: "flex", flexDirection:"row", justifyContent: "space-around"}}>
                <div className="internalCardContainer" style={{display:"flex", justifyContent:"center"}}>
                  <div
                    style={{ padding: "5px 30px", margin:"5px", width:"150px", backgroundColor: "#aaa799" }}
                    className={["button", "standard", "normalText", "normalMediumSize"].join(" ")}
                    onClick={exitDialog}
                  >
                    {t("cancel")}
                  </div>
                </div>
                <button
                  style={{
                    backgroundColor: isButtonDisabled ? '#fff' : '#0b1f82',
                    color: isButtonDisabled ? '#0b1f82' : '#fff',
                    border: isButtonDisabled ? '1px solid #0b1f82' : 'none',
                    cursor: isButtonDisabled ? "not-allowed" : "pointer",
                    padding: '5px 30px',
                    margin:"20px 0",
                    width:"150px"
                  }}
                  className={["button", "normalText", "normalMediumSize"].join(" ")}
                  onClick={() =>{
                    deleteWebhook()
                  }}
                  disabled={isButtonDisabled}
                >
                  {t("deactivate")}
                </button>
              </div>
          </div>
        </DialogContent>
      </Dialog>
      <Snackbar
        open={alertOpen}
        message={alertMessage}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        style={{ bottom: '80px' }}
      />
    </div>
  )
}

export default DeleteWebhookDialog;