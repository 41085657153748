import React from "react";
import Card from "@material-ui/core/Card";
import {checkNested, getCard} from "../../utils/utils";
import clockIcon from '../../../../assets/cardEntryIcons/Clock.svg';
import peopleLogo from '../../../../assets/cardEntryIcons/people.svg';
import creditCardLogo from '../../../../assets/cardEntryIcons/creditcard.svg';
import checkLogo from '../../../../assets/cardEntryIcons/greenCheck.svg';
import crossLogo from '../../../../assets/cardEntryIcons/redCross.svg';
import DataField from "../../utils/DataField";
import * as S from './styles';

function ActiveCardCard(props){
    let {card, texts} = props
    const cardStyles = {
        borderRadius: 8,
        width: "fit-content",
        minWidth: "520px",
        display: "flex",
        gap: 32,
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "8px 16px 8px 16px",
        backgroundColor: "#fff"
    }

    return (
        <Card style={cardStyles}>
            <S.InformationColumn style={{display:"flex", flexDirection:"column"}}>
                <DataField
                        titleImage={creditCardLogo}
                        titleTooltipText={texts.card_number}
                        alt={texts.card_number}
                        label={`${card.bin}••••••${card.last_four_digits}`}
                        size={"subtitleSize"}
                        titleNoWrap={true}
                        margin="0px"
                        display={"inline"}
                /> 
                <DataField
                        titleImage={peopleLogo}
                        titleTooltipText={texts.cardholder_name}
                        alt={texts.cardholder_name}
                        label={checkNested(card, "cardholder_name") ? card.cardholder_name  : "-"}
                        size={"subtitleSize"}
                        titleNoWrap={true}
                        margin="0px"
                        display={"inline"}
                /> 
                <DataField
                        titleImage={clockIcon}
                        titleTooltipText={texts.expiery_date}
                        alt={texts.expiery_date}
                        label={checkNested(card,"expiration_date") ? card.expiration_date : "-"}
                        size={"subtitleSize"}
                        titleNoWrap={true}
                        margin="0px"
                        display={"inline"}
                />
                {card.valid ? <S.CardStatusContainer valid={true}><img alt={texts.active_card} src={checkLogo}/>{texts.active_card}</S.CardStatusContainer> :
                <S.CardStatusContainer valid={false}><img alt={texts.inactive_card} src={crossLogo} />{texts.inactive_card}</S.CardStatusContainer>}
            </S.InformationColumn>
            <div style={{margin: "auto 0px auto auto"}}>
                {getCard(card)}
            </div>
        </Card>
    )
}



export default ActiveCardCard