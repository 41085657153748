import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #cccc;
    border-radius: 10px;
    padding: 21px;
    display:flex;
    flex-grow: 1;
    flex-direction: column;
`;
export const Header = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
`;

export const Title = styled.div`
    font-size: 16px;
    font-family: "Open Sans";
    color: #0b1f82;
    font-weight: bold;
`;


export const Subtitle = styled.div`
    margin: 4px 0;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    font-family: "Open Sans";
`;

export const ProcessesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const Toolbar = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    width: 100%;
`;

export const TableWrapper = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 100%;
    overflow-x: auto;
`;

export const Input = styled.input`
    outline-color: #0b1f82;
    font-family: 'Open Sans';
    font-size: 14px;
`;

export const InputWrapper = styled.div`
    width: 100%;
    max-width: 280px;
`;

export const SelectWrapper = styled.div``;

export const WorkInProgress = styled.div`
    padding: 8px;
    border-radius: 8px;
    border-style: dashed;
    color: orange;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ConsolidatedWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-top: 8px;
`;

export const Warn = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: bold;
    font-size: 12px;
    gap: 4px;
`;

export const ConsolidatedRow = styled.div`
    display: flex;
    flex-direction: row;
    gap: 24px;
    align-items: center;
    margin-top: 4px;
`;

export const Label = styled.div`
    font-weight: bold;
    color: #0b1f82;
`;

export const PassiveHighlighter = styled.div`
    background-color: #ff828255;
    padding: 0 8px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
`;